import { LayoutDefaultProps } from '@graphcommerce/next-ui'
import { GlobalProvider } from '../NextUi/GlobalProvider'
import { Footer } from './Footer'
import { Header } from './Header'
import { LayoutQuery } from './Layout.gql'
import { LayoutHome } from './LayoutHome'

export type LayoutNavigationHomeProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigationHome(props: LayoutNavigationHomeProps) {
  const { footer, header, menu, children, ...uiProps } = props

  return (
    <GlobalProvider>
      <LayoutHome
        {...uiProps}
        header={<Header menu={menu} header={header} />}
        footer={<Footer footer={footer} />}
      >
        {children}
      </LayoutHome>
    </GlobalProvider>
  )
}
